import Vue from 'vue'
import Router from 'vue-router'
import Index from './page/main/computer'
//import Home from './page/main/computer/home'
import Home from './page/main/computer/home2'
import MobileGame from './page/main/computer/mobileGame'
import H5Game from './page/main/computer/H5Game'
import Gift from './page/main/computer/gift'
import App from "./page/main/computer/app"
import Service from './page/main/computer/service'
import MgDetails from "./page/main/computer/mgDetails"
import H5Details from './page/main/computer/H5Details'
import searchResult from './page/main/computer/searchResult'
import personageCenter from "./page/main/computer/accountCenter/personageCenter"
import MyGame from './page/main/computer/accountCenter/myGame'
import EditData from './page/main/computer/accountCenter/editData'
import Security from './page/main/computer/accountCenter/security'
import MyGift from "./page/main/computer/accountCenter/myGift"
import News from './page/main/computer/news/news'
import NewsInfo from "./page/main/computer/news/newsInfo"
import Matter from './page/main/computer/matter/matter'
import About from './page/main/computer/matter/about'
import User from './page/main/computer/matter/user'
import Privacy from "./page/main/computer/matter/privacy"
import OpenService from "./page/main/computer/openService"
import MobileIndex from "./page/main/mobile/mobileIndex"
//import MobileHome from './page/main/mobile/mobileHome'
import MobileHome from './page/main/mobile/mobileHome2'
import Game from './page/main/mobile/mobileGame'
import MobileWeifare from "./page/main/mobile/welfare/mobileWeifare"
import MobileAccount from "./page/main/mobile/my/mobileAccount"
import BT from './page/main/mobile/bt'
import Played from './page/main/mobile/played'
import MobileH5 from "./page/main/mobile/mobileH5";
import Account from "./page/main/mobile/my/account"
import MobileLogin from "./page/main/mobile/mobileLogin"
import Opinion from "./page/main/mobile/my/opinion"
import GameDetails from "./page/main/mobile/gameDetails"
import MobileNews from "./page/main/mobile/welfare/mobileNews"
import MobileMyGift from './page/main/mobile/my/mobileMyGift'
import MobileMyGame from './page/main/mobile/my/mobileMyGame'
import MobileModPassword from './page/main/mobile/my/mobileModPassword'
import MobileMatter from './page/main/mobile/my/mobileMatter'
import Transaction from "./page/main/mobile/transaction/transaction"
import TransactionRecord from "./page/main/mobile/transaction/transactionRecord"
import GoodsDetails from './page/main/mobile/transaction/GoodsDetails'
import GameGift from './page/main/mobile/welfare/gameGift'
import KaiFu from './page/main/mobile/welfare/kaiFu'
import Software from './page/main/mobile/welfare/software'
import MobileSearchResult from './page/main/mobile/mobileSearchResult'
import ShouYou from "@/page/main/mobile/shouYou";
import Reset from "@/page/main/computer/login/reset"
import SelfPay from "@/page/main/pay/selfpay.vue"
import TempPage from "@/page/main/computer/tempPage.vue";

Vue.use(Router)


export default new Router({
    base: process.env.BASE_URL,
    scrollBehavior(to, from,savedPosition) {
        return {
            x: 0,
            y: 0
        }
    },
    mode: 'history',
    routes: [
        {
            path: '/',
            redirect: '/home'
        },
        {
            path: '/reset',
            name: 'Reset',
            component: Reset,
            meta: {
                title: '找回密码'
            }
        },
        {
            path: '/index',
            name: 'Index',
            component: Index,
            children:[
                {
                    path:"/home",
                    name:"Home",
                    component: Home,
                    meta:{
                        title:"七里网络",
                    },
                },
                {
                    path: '/app',
                    name: 'App',
                    component: App,
                    meta: {
                        title: '七里网络'
                    }
                },
                {
                    path: '/my',
                    //name: personageCenter,
                    component: personageCenter,
                    meta:{
                        title: '个人中心'
                    },
                    children:[
                        {
                            path: '/',
                            redirect: '/my/myGame'
                        },
                        {
                            path: '/my/myGame',
                            name: MyGame,
                            component: MyGame,
                            meta: {
                                title: '个人中心|我的游戏'
                            }
                        },
                        {
                            path: '/my/edit',
                            //name: EditData,
                            component: EditData,
                            meta: {
                                title: '个人中心|编辑资料'
                            }
                        },
                        {
                            path: '/my/security',
                            //name: Security,
                            component: Security,
                            meta: {
                                title: '个人中心|账号安全'
                            }
                        },
                        {
                            path: '/my/myGift',
                            //name: MyGift,
                            component: MyGift,
                            meta: {
                                title: '个人中心|我的礼包'
                            }
                        }
                    ]
                },
                {
                    path: '/mobileGame',
                    name: 'MobileGame',
                    component: MobileGame,
                    meta: {
                        title: '七里网络',
                    },
                },
                {
                    path: '/MgDetails',
                    name: 'MgDetails',
                    component: MgDetails,
                    meta: {
                        title: '游戏详情'
                    }
                },
                {
                    path: '/h5',
                    name: 'H5',
                    component: H5Game,
                    meta: {
                        title: '七里网络'
                    },
                },
                {
                    path: '/H5Details',
                    name: 'H5Details',
                    component: H5Details,
                    meta:{
                        title: '游戏详情'
                    }
                },
                {
                    path: '/gift',
                    name: 'Gift',
                    component: Gift,
                    meta: {
                        title: '七里网络'
                    }
                },
                {
                    path: '/service',
                    name: 'Service',
                    component: Service,
                    meta: {
                        title: '七里网络'
                    }
                },
                {
                    path: '/search',
                    name: 'searchResult',
                    component: searchResult,
                    meta:{
                        title: '搜索结果'
                    }
                },
                {
                    path: '/news',
                    name: 'News',
                    component: News,
                    meta:{
                        title: '七里网络'
                    },
                },
                {
                    path: '/newsInfo',
                    name: 'NewsInfo',
                    component: NewsInfo,
                    meta:{
                        title: '七里网络'
                    }
                },
                {
                    path: '/matter',
                   // name: 'Matter',
                    component: Matter,
                    meta:{
                        title: '七里网络'
                    },
                    children:[
                        {
                            path: '/',
                            redirect: '/about'
                        },
                        {
                            path: '/about',
                            name: 'About',
                            component: About,
                            meta: {
                                title: '关于我们'
                            }
                        },
                        {
                            path: '/user',
                            name: 'User',
                            component: User,
                            meta: {
                                title: '用户协议'
                            }
                        },
                        {
                            path: '/privacy',
                            name: 'Privacy',
                            component: Privacy,
                            meta: {
                                title: '隐私协议'
                            }
                        }
                    ]
                },
                {
                    path: '/openService',
                    name: 'OpenService',
                    component: OpenService,
                    meta:{
                        title: '七里网络'
                    }
                }
            ]
        },
        {
            path: '/mobile/index',
            //name: 'MobileIndex',
            component: MobileIndex,
            meta:{
                title: '七里网络'
            },
            children:[
                {
                  path: '/',
                  redirect: '/mobile/home'
                },
                {
                    path: '/mobile/home',
                    name: 'MobileHome',
                    component: MobileHome,
                    meta: {
                        title: '首页'
                    }
                },
                {
                    path: '/mobile/game',
                    name: 'Game',
                    component: Game,
                    meta: {
                        title: '游戏'
                    }
                },
                {
                    path: '/mobile/weifare',
                    name: 'MobileWeifare',
                    component: MobileWeifare,
                    meta: {
                        title: '福利'
                    }
                },
                {
                    path: '/mobile/account',
                    name: 'MobileAccount',
                    component: MobileAccount,
                    meta: {
                        title: '个人中心'
                    }
                },
            ]
        },
        {
            path: '/mobile/bt',
            name: 'BT',
            component: BT,
            meta: {
                title: "七里网络"
            }
        },
        {
            path: '/mobile/played',
            name: 'Played',
            component: Played,
            meta: {
                title: "七里网络"
            }
        },
        {
            path: '/mobile/h5',
            name: 'MobileH5',
            component: MobileH5,
            meta: {
                title: '七里网络'
            }
        },
        {
            path: '/mobile/shouYou',
            name: 'ShouYou',
            component: ShouYou,
            meta:{
                title: '七里网络'
            }
        },
        {
            path: '/mobile/login',
            name: 'MobileLogin',
            component: MobileLogin,
            meta: {
                title: '登录'
            }
        },
        {
            path: '/account',
            name: 'Account',
            component: Account,
            meta:{
                title: '个人信息'
            }
        },
        {
            path: '/mobile/opinion',
            name: 'Opinion',
            component: Opinion,
            meta:{
                title: '意见反馈'
            }
        },
        {
            path: '/mobile/gameDetails',
            name: 'GameDetails',
            component: GameDetails,
            meta:{
                title: '游戏详情'
            }
        },
        {
            path: '/mobile/news',
            name: 'MobileNews',
            component: MobileNews,
            meta:{
                title: '资讯'
            }
        },
        {
            path: '/mobile/myGift',
            name: 'MobileMyGift',
            component: MobileMyGift,
            meta:{
                title: '我的礼包'
            }
        },
        {
            path: '/mobile/myGame',
            name: 'MobileMyGame',
            component: MobileMyGame,
            meta:{
                title: '我的游戏'
            }
        },
        {
            path: '/mobile/modPwd',
            name: 'MobileModPassword',
            component: MobileModPassword,
            meta:{
                title: '修改密码'
            }
        },
        {
            path: '/mobile/matter',
            name: 'MobileMatter',
            component: MobileMatter,
            meta:{
                title: '关于我们'
            }
        },
        {
            path: '/mobile/transaction',
            name: 'Transaction',
            component: Transaction,
            meta:{
                title: '交易'
            }
        },
        {
            path: '/mobile/record',
            name: 'TransactionRecord',
            component: TransactionRecord,
            meta:{
                title: '交易记录'
            }
        },
        {
            path: '/mobile/goodsDetails',
            name: 'GoodsDetails',
            component: GoodsDetails,
            meta:{
                title: '七里网络'
            }
        },
        {
            path: '/mobile/gift',
            name: 'GameGift',
            component: GameGift,
            meta:{
                title: '游戏礼包'
            }
        },
        {
            path: '/mobile/kaiFu',
            name: 'KaiFu',
            component: KaiFu,
            meta:{
                title: '开服'
            }
        },
        {
            path: '/mobile/software',
            name: 'Software',
            component: Software,
            meta:{
                title: 'app下载'
            }
        },
        {
            path: '/mobile/result',
            name: 'MobileSearchResult',
            component: MobileSearchResult,
            meta:{
                title: '搜索结果页'
            }
        },
        {
            path: '/jiabi',
            name: 'SelfPay',
            component: SelfPay,
            meta:{
                title: '七里币自助充值'
            }
        },
        {
            path: '/tu/*',
            name: "TemPage",
            component: TempPage,
            meta:{
                title: ' '
            }
        },
        {
            path: '/cx/*',
            name: "TemPage",
            component: TempPage,
            meta:{
                title: ' '
            }
        },
        {
            path: '/zy/*',
            name: "TemPage",
            component: TempPage,
            meta:{
                title: ' '
            }
        },
        {
            path: '/du/*',
            name: "TemPage",
            component: TempPage,
            meta:{
                title: ' '
            }
        },
        {
            path: '/q/*',
            name: "TemPage",
            component: TempPage,
            meta:{
                title: ' '
            }
        },
        {
            path: '/b/*',
            name: "TemPage",
            component: TempPage,
            meta:{
                title: ' '
            }
        },
        {
            path: '/h/*',
            name: "TemPage",
            component: TempPage,
            meta:{
                title: ' '
            }
        },
        {
            path: '/ty/*',
            name: "TemPage",
            component: TempPage,
        },
        {
            path: '/yu/*',
            name: "TemPage",
            component: TempPage,
        },
    ]
})

/*重复路由报错*/
const RouterPush = Router.prototype.push
Router.prototype.push = function push (to) {
    return RouterPush.call(this, to).catch(err => err)
}
const RouterReplace = Router.prototype.replace
Router.prototype.replace = function replace (to) {
    return RouterReplace.call(this, to).catch(err => err)
}